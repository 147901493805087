.error-message {
   @include flex(column, center, center);
   height: 100vh;
   h1 {
      color: yellow;
      font-size: 5rem;
      font-weight: 100;
      text-transform: uppercase;
      background: black;
   }

   h1,
   div {
      width: 80rem;
   }

   button {
      background: none;
      border: none;
      color: lightblue;
      font-size: 3rem;
      font-weight: 100;

      &:focus {
         border: none;
         outline: none;
      }
   }
}
