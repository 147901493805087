$color-save: #353a47;
$color-save-hover: #000;
$color-cancel: #dc136c;
$color-cancel-hover: #b40e4b;
$color-btn-print: #dc136c;
$color-btn-print-hover: #b40e4b;
$color-btn-link: #4a8ed6;
$color-btn-link-hover: #2d5681;
$color-btn-print-pdf: #b30b00;
$color-btn-print-pdf-hover: #860900;
$color-edit: dodgerblue;
$color-edit-hover: $color-save;
$button-edit-hover-animation-enabled: false;

.button {
   background-color: #333;
   border: none;
   color: white;
   font-size: 1.2rem;
   font-weight: 700;
   min-width: 10rem;
   padding: 1rem;
   margin-right: 1rem;
   position: relative;
   z-index: 5;
   transition: all 0.25s ease;
   overflow: hidden;

   &:disabled {
      opacity: 0.3;
   }

   &:focus,
   &:focus-visible {
      box-shadow: 0 0 0.5rem $color-save;
      border: none;
      outline: none;
   }

   &,
   &::before {
      border-radius: 0.3rem;
      box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.4);
   }

   &::before {
      transition: all 0.25s ease;
      content: '';
      background-color: #60935d;
      position: absolute;
      transform: translateX(-105%);
      top: 0;
      left: 0;
      z-index: -10;
      width: 100%;
      height: 100%;
   }

   &:not(:disabled):hover::before {
      transform: translateX(0);
   }

   &.save {
      background-color: $color-save;

      &::before {
         background-color: $color-save-hover;
      }
   }

   &.cancel {
      background-color: $color-cancel;

      &::before {
         background-color: $color-cancel-hover;
      }
   }

   &.edit {
      color: $color-edit;
      padding: initial;
      background-color: transparent;
      box-shadow: none;
      transition: all 500ms ease;

      &::before {
         background-color: $color-edit-hover;
      }

      &:not(:disabled):hover::before {
         @if $button-edit-hover-animation-enabled {
            transform: translateX(0);
         } @else {
            transform: translateX(-105%);
         }
      }

      &:hover {
         color: #fff;
      }
   }

   &.btn-print {
      background-color: $color-btn-print;
      margin-right: 0;

      &::before {
         background-color: $color-btn-print-hover;
      }
   }

   &.btn-print-pdf {
      @include flex(row, center, center);
      background-color: $color-btn-print-pdf;
      padding: 1rem;

      &::before {
         background-color: $color-btn-print-pdf-hover;
      }
   }

   &.link {
      @include flex(row, flex-start, center);
      all: unset;
      background-color: transparent;
      color: $color-btn-link;
      cursor: pointer;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 400;
      text-shadow: 0 0 0.15rem $color-btn-link;

      &::before {
         all: unset;
      }
      &:hover {
         color: $color-btn-link-hover;
         & > svg {
            color: #000;
         }
      }

      & > svg {
         color: $color-btn-link;
         transition: all 0.25s ease;
         margin-top: -0.25rem;
      }
      & > span {
         transition: all 0.25s ease;
      }
      & > span:hover {
         margin-left: 1rem !important;
      }
   }
}

.theme-dark {
   .link {
      color: #2d5681;

      &:hover {
         color: $color-btn-link;
      }
   }
}
