.custom-alert {
   @include flex(row, center, center);
   background-color: rgba($color: #000000, $alpha: 0.5);
   color: #333;
   height: 100%;
   left: 0;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 100;

   .custom-alert-popup {
      @include flex(column, space-between, flex-start);
      background-color: #fff;
      border-radius: 0.25rem;
      box-shadow: 0 0 1rem #444;
      padding: 1.5rem;
      width: 60rem;

      .custom-alert-title,
      .custom-alert-content,
      .custom-alert-footer {
         width: 100%;
      }

      .custom-alert-title {
         @include flex(row, flex-start, center);
         background-color: #353a47;
         border-radius: 0.2rem;
         color: #fff;
         font-size: 1.6rem;
         font-weight: 700;
         height: 4rem;
         margin-bottom: 2rem;
         padding-left: 1rem;
         text-transform: uppercase;

         .print-title {
            @include flex(row, space-between, center);
            width: 100%;

            cursor: pointer;
         }
      }

      .custom-alert-footer {
         @include flex(row, flex-end, center);
         font-size: 1.2rem;

         .custom-alert-btn {
            border-radius: 0.3rem;
            border: 1px solid;
            color: white;
            display: inline-block;
            margin-left: 0.5rem;
            padding: 1rem;
            width: 12rem;
         }

         .btnPrimary {
            background-color: $ok-color;

            &:focus {
               outline: none;
               border: none;
               box-shadow: 0 0 0.25rem $ok-color;
            }
         }

         .btnSecondary {
            background-color: $cancel-color;
         }

         .btnPrimary,
         .btnSecondary {
            font-weight: 400;
         }
      }

      &.pregled-svih-keno-listica-data-table {
         @media only screen and (max-width: 1194px) {
            transform: scale(0.9) !important;
         }
      }
   }
}
