.keno-odigrani-brojevi-container {
   @include flex(row, center, center);
   flex-wrap: wrap;

   .odigrani-broj {
      @include flex(row, center, center);
      border: 0.1rem solid #fff;
      font-weight: 900;
      height: 4rem;
      margin: 0.6rem;
      position: relative;
      width: 4rem;

      .star {
         position: absolute;
         right: -0.8rem;
         top: -0.8rem;
         color: #b40e4b;
      }
   }
}
