.search-term {
   width: 100%;
   // margin-bottom: 2rem;
   * {
      font-size: 1.4rem;
   }
   label {
      font-weight: 700;
   }
   input {
      border-radius: 0.25rem;
      padding: 1.5rem 1.5rem 1.5rem 0.5rem;

      &::placeholder {
         color: rgba(0, 0, 0, 0.5);
      }
   }
}

.search-term-container {
   @include flex(row, space-between, center);

   button {
      font-size: 1.2rem;
   }
}
