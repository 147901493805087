.pregled-keno-listica {
   .brojevi {
      margin-top: 13rem;

      h2 {
         font-weight: 700;

         &:last-of-type {
            margin-top: 5rem;
         }
      }
   }
}
