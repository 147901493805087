.pregled-listica {
   tr {
      //makni nasljeđeni :hover event
      pointer-events: none;
      td:last-of-type {
         font-weight: 500;
      }
   }

   .buttons {
      @include flex(row, space-between, center);

      .report-buttons {
         button:last-of-type {
            margin-right: 0;
         }
      }
   }
}
