$user-avatar-background-color: #fff;

.user-avatar {
   position: relative;
   width: 100%;
   height: $navbar-height;
   @include flex(row, flex-end, center);
   cursor: pointer;

   .user-info-panel {
      color: black;
      background-color: $user-avatar-background-color;
      padding: 2rem 4rem;
      position: fixed;
      right: 1rem;
      box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
      border-radius: 0.4rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      z-index: 1;

      & > * {
         text-align: left;
      }

      h2 {
         font-size: 3rem;
         margin: 0;
         padding: 0;
         font-weight: 100;
      }

      span {
         color: red;
      }

      //   .panel-arrow::before {
      //      content: '';
      //      position: absolute;
      //      top: -1rem;
      //      right: 1rem;
      //      width: 2rem;
      //      height: 2rem;
      //      transform: rotate(45deg);
      //      background-color: $user-avatar-background-color;
      //      z-index: -1;
      //   }
   }
}
