.openstreet-koordinate {
   @include flex(row, space-between, center);
   font-weight: 700;
   font-size: 1.4rem;
   div {
      span {
         color: red;
      }
   }
}

.azuriranje-prodajnog-mjesta-header {
   @include flex(row, space-between, center);
   h2 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
      font-weight: 700;
   }
}
