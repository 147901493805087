.prikaz-podataka-kumulativa {
   font-size: 1.2rem;
   td {
      &:first-child {
         font-weight: 700;
      }
      padding: 0.2rem;
   }

   th {
      &:first-child {
         color: #ff0;
         font-weight: 700;
      }
      background-color: #000;
      color: white;
      font-weight: normal;
   }
}
