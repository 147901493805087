div.addArticle{
    width: 500px;
    background-color: #333;
    padding: 20px;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px 3px #333;
    
    input:focus{
        box-shadow: 0 0 2px 2px #fff;
        border:0;
    }
}