$background-color-dark: #191d2c;
$background-color-dark-hover: #000000;

$color-icon-light: #f5e1da;
$color-text-light: #f1f1f1;
$color-light-hover: #0f111a;

$background-color-light: #085f63;
$background-color-light-hover: #49beb7;

$box-shadow-dark: 0 0 2rem 0.25rem #fff;
$box-shadow-light: 0 0 2rem 0.25rem #085f63;

$hover-shadow-dark: drop-shadow(0 0 4rem rgb(255, 255, 255));
$hover-shadow-light: drop-shadow(0 0 4rem rgb(117, 181, 255));

$font-size: 1.1rem;

.page-links-container {
   @include flex(row, center, flex-start);
   align-content: center;
   flex-wrap: wrap;
   min-height: inherit;

   a {
      font-size: 1.6rem;
      padding: 0;
      transition: all 0.25s ease;

      .page-link {
         @include flex(column, flex-start, center);
         border: 0.1rem solid rgba(255, 255, 255, 0.05);
         height: 20rem;
         margin: 1rem;
         width: 20rem;
         border-radius: 0.5rem;

         &:hover {
            margin: 0 5rem;

            .page-link-header {
               .icon {
                  transform: scale(2) translate3d(0, 0, 0) perspective(1px);
               }
            }
            .page-link-footer {
               letter-spacing: 0.075rem;
               color: orange;
            }
         }

         > div {
            width: 100%;
         }

         .page-link-header {
            @include flex(row, center, center);
            font-size: 3rem;
            height: 70%;
            border-radius: 0.5rem;
         }

         .page-link-footer {
            @include flex(row, center, center);
            height: 30%;
            transition: all 1s ease;
            font-size: $font-size;
            text-align: center;
            font-weight: 700;
         }

         & .icon {
            transition: all 0.5s ease;
         }

         &.theme-dark {
            color: #fff;
            background: $background-color-dark;
            transition: all 0.5s ease;

            & .icon {
               color: orange;
            }

            .page-link-header {
               background-color: #000;
            }

            &:hover {
               background: $background-color-dark-hover;
               border-color: rgba(255, 255, 255, 0.1);
               box-shadow: $box-shadow-dark;
            }
         }

         &.theme-light {
            color: $color-text-light;
            background: $background-color-light;
            transition: all 0.5s ease;
            border: 0.1rem solid rgba(0, 0, 0, 0.05);
            // box-shadow: $box-shadow-light;

            & .icon {
               color: $color-icon-light;
            }

            .page-link-header {
               background-color: $background-color-light-hover;
            }

            &:hover {
               background: $background-color-light-hover;
               border-color: rgba(100, 100, 100, 0.25);
               border-radius: 0.5rem;
               box-shadow: $box-shadow-light;
               // box-shadow: 0 0 1rem black;
               color: $color-light-hover;

               .icon {
                  color: $color-light-hover;
               }

               .page-link-footer {
                  color: $color-light-hover;
               }
            }
         }
      }
   }
}
