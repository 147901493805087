.tbl-azuriranje-provizije {
   font-size: 1.4rem;
   td:first-of-type {
      width: 15rem;
   }
   td:last-of-type {
      font-weight: bold;
   }
}

.tbl-header {
   font-weight: 700;
   font-size: 1.5rem;
   margin-top: 4rem;
   margin-left: 2rem;
}
