.page {
   @include flex(row, center, center);
   background-color: transparent;
   min-height: calc(100vh - #{$navbar-height} - #{$footer-height});
   padding: 2rem;
   width: 100vw;
   z-index: 10;
   position: relative;

   .page-content {
      background: #fff;
      box-shadow: 0 0 1.5rem rgba(200, 200, 200, 0.75);
      border-radius: 0.5rem;
      max-height: calc(100vh - #{$navbar-height} - #{$footer-height} - 2.5rem);
      overflow: auto;
      padding: 3rem;
      width: 120rem;
   }

   // .page-content-light {
   //    background: #fff;
   // }

   // .page-content-dark {
   //    background: #0c0e1a;
   // }
}
