// $tr-hover-color: rgba(162, 161, 221, 1);
$tr-hover-color: lighten($color-save, 40%);

.table-overview-container {
   width: 100%;
   margin: auto;

   h2 {
      background-color: #000;
      color: yellow;
      padding: 0.5rem;
      text-transform: uppercase;
      font-size: 1.2rem;
   }

   table {
      font-size: 1.2rem;
      font-weight: 700;
      width: 100%;
      color: #333;

      thead {
         tr {
            th {
               border-top: none;
               border-bottom: 0.3rem solid #000;
            }
         }
         // &.table-header-light {
         //    color: #000;
         // }

         // &.table-header-dark {
         //    color: #fff;
         //    th {
         //       border-bottom: 0.3rem solid #fff;
         //    }
         // }
      }

      tbody {
         // height: calc(100vh - #{$navbar-height * 6});

         // &.tbody-light {
         //    color: #000;
         // }
         // &.tbody-dark {
         //    color: #ccc;
         // }

         tr {
            cursor: pointer;
            transition: all 0.25s ease;

            td {
               border: none;
            }

            // &.tr-dark:nth-child(odd) {
            //    background: #202135;
            // }

            // &.tr-dark:nth-child(even) {
            //    background: #1b132b;
            // }

            // &.tr-light:nth-child(odd) {
            //    background: #fff;
            // }
            // &.tr-light:nth-child(even) {
            //    background: rgba(0, 0, 0, 0.05);
            // }

            &:nth-child(odd) {
               background: #fff;
            }
            &:nth-child(even) {
               background: rgba(0, 0, 0, 0.05);
            }

            &:hover {
               background: $tr-hover-color !important;
               color: white !important;
            }
         }
      }

      tfoot {
         tr:first-of-type {
            border-top: none;
            border-top: 0.3rem solid #000;
         }
         td:not(:first-child) {
            text-align: right;
            color: green;
         }
      }
   }
}
