.alert {
   margin-bottom: 1px;
   height: 30px;
   line-height: 30px;
   padding: 0px 15px;
   font-size: 0.8em;
   transition: all 0.2s ease;
}

.confirm-highlight {
   font-weight: 700;
   color: #f00;
}

.myToast {
   font-size: 1.2rem;
   font-weight: 700;
}

.toastIcon {
   color: yellow;
   font-size: 1.5rem;
   display: block;
   margin-bottom: 0.5rem;
   filter: drop-shadow(0.1rem 0.1rem 0.25rem rgba(0, 0, 0, 0.5));
}

.lbl-sufix {
   color: #dc136c;
   font-weight: 700;
   font-size: 1.6rem;

   &:before {
      content: '[ ';
   }

   &:after {
      content: ' ]';
   }
}

*::-webkit-scrollbar {
   width: 0.2rem;
   height: 100%;
}

*::-webkit-scrollbar-track {
   background: transparent;
}

*::-webkit-scrollbar-thumb {
   background-color: #ccc;
   border-radius: 2rem;
   border: transparent;
}

@supports (scrollbar-color: red red) {
   * {
      // scrollbar-color: #333, #fff;
      scrollbar-width: thin;
   }
}

.form-control::placeholder {
   color: rgba(0, 0, 0, 0.5);
}

.zastupnici-search-bar {
   @include flex(row, space-between, center);
}
