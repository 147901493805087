.footer {
   @include flex(row, center, center);
   background-color: #efffff;
   bottom: 0;
   color: #ccc;
   font-size: 1rem;
   height: $footer-height;
   left: 0;
   position: absolute;
   width: 100%;
}
.footer-dark-theme {
   background-color: #000;
}
