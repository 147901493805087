.chart-tooltip {
   padding: 1rem;
   font-weight: 100;
   font-size: 1rem;
   p {
      padding: 0;
      margin: 0;

      &.label {
         font-size: 1.1rem;
         font-weight: 900;
         margin-bottom: 0.5rem;
      }
   }

   table {
      color: #666;
      font-weight: 700;

      td:last-child {
         color: orange;
         padding-left: 1rem;
      }
   }
}

.recharts-legend-item-text {
   font-size: 1.2rem;
}

.pie-chart-container {
   @include flex(row, center, center);
}

tspan {
   font-size: 1rem;
   font-weight: 700;
}
