$border: 1px solid #eee;

$kombinacija-color: #fff;
$kombinacija-bg-color: #219ebc;

$winning-color: orange;
$winning-bg-color: #0f111a;

.kombinacije-container {
   height: 100%;
   padding-top: 2rem;

   .bingo-kombinacija {
      background-color: #fff;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
      padding: 0.3rem;
      @include flex(row, flex-start, flex-start);
      //border: 1px solid black;
      width: 30rem;
      height: 9.5rem;
      margin: 0.5rem 0;

      .header {
         @include flex(row, center, center);
         height: 8.9rem;
         margin-right: 0.25rem;
         width: 2rem;
         background-color: $kombinacija-bg-color;
         color: $kombinacija-color;
         font-size: 1.3rem;
         font-weight: 700;
         writing-mode: vertical-rl;
      }
      .numbers {
         width: 100%;
         display: grid;
         grid-template-columns: repeat(9, 1fr);
         gap: 0.1rem;

         .number {
            @include flex(row, center, center);
            font-size: 1.3rem;
            font-weight: bold;
            border: $border;
            width: 2.9rem;
            height: 2.9rem;
         }
         .winning-number {
            color: $winning-color;
            background-color: $winning-bg-color;
         }
      }
   }
}
