.form {
   background-color: #f1f1f1;
   box-shadow: 0 0 0.5em #ccc;
   padding: 3rem;
   border-radius: 0.5rem;

   input {
      padding: 1.4rem;
      font-size: 1.4rem;
   }
   label {
      font-weight: 700;
      font-size: 1.6rem;
      margin-bottom: 0;
   }

   .alert {
      font-size: 1.2rem;
      font-weight: 700;
   }

   .form-buttons {
      @include flex(row, space-between, center);
   }
}

.btn-list-link {
   color: #999;
   font-size: 1.2rem;
}

.form-add {
   background-color: #fff;
}

.form-control:disabled {
   background: #f5f5f5;
   color: #999;
}

.form-group {
   label {
      font-size: 1.4rem;
      text-transform: uppercase;
   }
}
