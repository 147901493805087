div.avatar-editor {
   .avatar-editor-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.95);
      cursor: pointer;
      z-index: 5;
   }

   .avatar-editor-container {
      width: 60vw;
      min-width: 500px;
      max-width: 700px;
      min-height: 450px;
      max-height: 450px;
      padding: 1em;
      // min-height: 350px;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
      border-radius: 3px;
      box-shadow: 0 0 5px #fff;
      //   pointer-events: none;
      cursor: pointer;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      h2 {
         text-transform: uppercase;
         font-weight: 700;
         font-size: 1em;
      }
   }
}
