$ok-color: #007bff;
$cancel-color: #333;
$edit-color: #248140;
$light-theme-background: #f5f5f5;
// $dark-theme-background: linear-gradient(#18191a, #222, #18191a);
$dark-theme-background: linear-gradient(#0f111a, #090b10, #0f111a);

$navbar-height: 6rem;
$footer-height: 4rem;

html {
   height: 100vh;
   font-size: 62.5%;
   /* iPad Pro 11' */
   @media only screen and (max-width: 1194px) {
      font-size: 62.5%;
   }

   /* Handle 125% and 150% Windows 10 Font Scaling on 96dpi monitors */
   // @media (resolution: 125dpi) {
   //    html {
   //       font-size: 50%;
   //    }
   // }
}

.light-theme {
   background: $light-theme-background;
}

.dark-theme {
   background: $dark-theme-background;
}

body {
   //font-family: 'Roboto', sans-serif !important;
   font-family: 'Roboto Condensed', sans-serif !important;
   background-color: $light-theme-background;
   height: inherit;
   overflow: hidden;

   transition: all 0.2s ease;
   -webkit-print-color-adjust: exact !important;
}

.app {
   height: 100vh;
   overflow: auto;
   //  background: $dark-theme-background;
}

table.table-details {
   font-size: 1.2rem;
   tbody {
      td:first-of-type {
         font-weight: 700;
      }
   }
}
