.print-modal {
   width: 100rem;
   height: 50rem;
   overflow: hidden;
}

.print {
   width: 90vw;
   margin: auto;
}

@media print {
   body {
      -webkit-print-color-adjust: exact !important;
      color-adjust: exact !important;
   }
   .print {
      color-adjust: exact !important;
      -webkit-print-color-adjust: exact !important;

      .print-header {
         margin-bottom: 2rem;
         font-size: 2.5rem;
         font-weight: 700;
      }

      table.table {
         font-size: 1rem;
         tbody {
            /*  parni  */
            tr:nth-child(even) {
               background-color: #fefefe;
               z-index: 10;
            }
            /*  neparni  */
            tr:nth-child(odd) {
               background-color: #fff;
               z-index: 10;
            }

            td {
               // position: relative;
               background: transparent !important;
            }
            // td:before {
            //    background-color: yellow;
            //    border: 0;
            //    content: '';
            //    display: block;
            //    height: 100%;
            //    left: 0;
            //    margin: 0;
            //    padding: 0;
            //    position: absolute;
            //    top: 0;
            //    z-index: -1;
            //    width: 100%;
            // }
         }
      }
   }

   @page {
      margin-top: 1.6cm;
   }
}
