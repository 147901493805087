// @Makni vizualne artefekte sa ant autocomplete polja
.ant-select {
   border: none !important;
}
.ant-select-disabled {
   .ant-select-selection-item {
      color: #999;
   }
}

// div.ant-select-selector {
// }

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
   border-color: #d9d9d9 !important;
   box-shadow: none !important;
}

//@at-rootAUTOCOMPLETE background opcije preko koje se prolazi
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
   background: #f5f5f5;
   // color: white;
}

div.form-group .ant-picker-input input {
   padding: 0;
}

/* Print ikona */
span.anticon.anticon-printer {
   margin: 0 !important;
}

/* DDL opcije */
// .ant-select-item-option-content,
// .ant-select-selection-item {
//    font-size: 1.2rem;
// }

/*
   Select element
*/
.ant-select-selection-placeholder,
.ant-select-selection-search-input,
.ant-select-selection-item,
.ant-select-item-option-content,
.ant-select-item,
.ant-select-item-option,
.ant-select-item-option-active {
   font-size: 1.4rem !important;
}

/*
   Datepicker
*/

input[placeholder] {
   font-size: 1.4rem !important;
}

// .ant-picker-panel-container {
//    transform: scale(0.8);
// }

/*    STEPS    */
$steps-dot-color: #000;
$steps-description-color: #ccc;

.ant-steps-item-description {
   color: $steps-description-color !important;
   // opacity: 0.5;
}
// boja točke
.ant-steps-item-finish
   .ant-steps-item-icon
   > .ant-steps-icon
   .ant-steps-icon-dot {
   // background: #1890ff;
   background: $steps-dot-color !important;
}

.ant-steps-item-process
   .ant-steps-item-icon
   > .ant-steps-icon
   .ant-steps-icon-dot {
   background: $steps-dot-color !important;
}

.ant-steps-item-finish
   > .ant-steps-item-container
   > .ant-steps-item-tail::after {
   background-color: #979eb1 !important;
}

/* AVATAR ICON */
span.anticon-user {
   height: inherit;
   @include flex(row, center, center);
   cursor: pointer;
}
