.homepage {
   @include flex(column, center, center);
   min-height: calc(100vh - #{$navbar-height});

   h1 {
      font-size: 9rem;
      font-weight: 100;
      margin-bottom: 0;

      @media only screen and (max-width: 1024px) {
         font-size: 8rem;
      }

      background-clip: text;
      -webkit-text-fill-color: transparent;
   }

   p {
      color: orange;
      font-size: 1rem;
      font-weight: 700;
   }
}
.homepage-dark {
   background: -webkit-linear-gradient(
      rgb(183, 218, 255) 20%,
      rgba(255, 165, 0, 0) 80%
   );
   text-shadow: 0 -0.5rem 1rem rgba(183, 218, 255, 0.5);
}
.homepage-light {
   background: -webkit-linear-gradient(
      rgb(40, 179, 214) 20%,
      rgba(144, 26, 28, 0) 80%
   );
   text-shadow: 0 -0.5rem 1rem rgba(40, 179, 214, 0.5);
}

.light-theme .homepage p {
   color: #999;
}
