.background-line {
   @include flex(row, center, center);
   left: -5rem;
   position: absolute;
   top: calc((100vh - 600px) / 2);
   top: 0;
   width: 105vw;
   height: 100vh;
   z-index: 1;

   // @media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
   //    top: 40%;
   // }
}
