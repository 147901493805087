$avatar-width-height: 150px;

div.rgister-users {
   width: 60vw;
   margin: auto;
   max-width: 70rem;
   //    padding: 20px;
   background-color: #fff;
   border: 0.1rem solid #eee;
   border-radius: 0.3rem;
   box-shadow: 0 0 0.5rem #ccc;

   header {
      text-align: center;
      background-color: whitesmoke;
      height: 5rem;
      h1 {
         @include flex(row, flex-start, center);
         font-weight: 700;
         font-size: 1.6rem;
         text-transform: uppercase;
         height: inherit;

         padding-left: 1.5rem;
      }
   }

   .data {
      padding: 2rem;
      display: flex;
      background-color: inherit;

      .user-data {
         .alert,
         input,
         label,
         button {
            font-size: 1.4rem;
         }
         label {
            font-weight: 700;
            margin: 1rem 0 0;
         }

         width: 50%;
      }

      .user-avatar {
         @include flex(row, center, center);
         width: 50%;
         background-color: inherit;

         button {
            border: none;
            background-color: inherit;
            outline: none;

            img {
               max-width: $avatar-width-height;
            }
         }
      }
   }
}
