div.not-found {
    @include flex(column, center, center);
    height: 100%;
    position: relative;
    z-index: 100;

    a {
        display: inline-block;
        margin-top: 5rem;
        color: dodgerblue;
    }
}

.not-found-dark {
    h1 {
        color: #fff;
    }
    h2 {
        color: orange;
    }
}

.not-found-light {
    color: #000;
    h2 {
        color: dodgerblue;
    }
}
