$color-navbar-button: #353a47;
$color-navbar-button-hover: #353a47;

div.navbar {
   width: 100vw;
   height: $navbar-height;
   background: #fff;
   box-shadow: 0 0 0.75rem #e1e1e1;

   @include flex(row, space-between, center);
   position: relative;
   z-index: 9000;

   padding: 0 2.5rem;

   .logo {
      @include flex(row, flex-start, center);
      width: 20rem;
      height: inherit;
      img {
         height: 3.5rem;
      }
   }

   .login {
      width: 20rem;
      color: dodgerblue;
      font-size: 1.4rem;
      font-weight: 700;
      text-align: right;
   }

   .navigationLinks {
      @include flex(column, center, center);
      height: inherit;

      ul {
         @include flex(row, space-between, center);
         height: inherit;
         margin: 0;
         padding: 0;

         li {
            list-style: none;
            position: relative;

            a.active {
               color: #a390dc !important;
               font-weight: 700 !important;
            }
            a.nav-link.active {
               color: #a390dc !important;
            }

            a,
            button {
               color: $color-navbar-button;
               height: inherit;
               font-size: 1.4rem;
               font-weight: 700;
               margin: 0 0.5rem;
               padding: 0.5rem 1.5rem 0.25rem;
               text-align: center;
               text-decoration: none;
               text-shadow: 0 0 0.1rem #ccc;
               text-transform: uppercase;

               &:hover {
                  color: #a390dc;
               }
            }

            div {
               @include flex(row, center, center);
               background-color: #fff;
               border-top: none;
               border: 0.1rem solid rgba(0, 0, 0, 0.05);
               box-shadow: 0 0 1rem rgba(255, 255, 255, 0.75);
               left: 0;
               min-height: 4rem;
               padding: 1rem 0;
               position: fixed;
               text-align: center;
               top: $navbar-height;
               width: 100vw;
               z-index: 100;

               h2 {
                  margin: 0;
                  padding: 0;
                  font-weight: 700;
                  font-size: 1.4rem;
                  text-transform: uppercase;
               }

               ul.menu-dropdown {
                  // https://css-tricks.com/auto-sizing-columns-css-grid-auto-fill-vs-auto-fit/
                  width: 90%;
                  display: grid;
                  grid-auto-flow: row;
                  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));

                  li.submenu-item {
                     display: block;
                     transition: all 0.2s ease-in-out;
                     margin: 0.5rem 0;
                     border-right: 0.1rem solid #ddd;

                     a {
                        @include flex(row, flex-end, center);
                        color: #999;
                        border-radius: 0.2rem;
                        font-size: 1.2rem;
                        font-weight: 400;
                        min-width: 20rem;
                        text-align: right;
                        width: 100%;

                        &:hover {
                           color: #000;
                           background-color: transparent;
                           box-shadow: none;
                        }
                     }
                  }
               }
            }
         }
      }
   }

   button {
      outline: none;
      border: none;
      background: none;
   }
}
