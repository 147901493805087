.pagination {
   .page-item {
      .page-link {
         /* pagination btn initial */
         color: $color-save;
      }

      &.active {
         .page-link {
            /* pagination btn active */
            font-weight: 700;
            color: #fff;
            background-color: $color-save;
            border-color: $color-save;
         }
      }
   }
}
