.info-vrijeme-upisa {
   @include flex(row, flex-end, center);
   margin-top: 1rem;
   margin-bottom: 1rem;

   .info {
      font-size: 1rem;
      opacity: 0.5;
      display: inline-block;
      font-weight: 700;
      span {
         font-weight: 400;
      }
   }
}
