$h1-color-light: #5aaaff;
$h1-color-dark: #2d5681;

.page-header {
   h1 {
      font-size: 2.5rem;
      font-weight: 100;
      margin-bottom: 2rem;
      text-align: right;
      text-transform: uppercase;

      &.theme-light {
         color: $h1-color-light;
         text-shadow: 0 0 0.3rem $h1-color-light;
      }
      &.theme-dark {
         color: $h1-color-dark;
         text-shadow: 0 0 0.3rem $h1-color-dark;
      }
   }
}
