$color-settings-background: #000;

.setup-container {
   @include flex(row, flex-end, center);
   height: 0;
   background-color: $color-settings-background;
   position: relative;
   overflow: hidden;
   z-index: 10;

   &.theme-dark {
      background-color: #000;
   }

   &.theme-light {
      background-color: #153b50;
   }

   .language,
   .theme {
      @include flex(row, flex-end, center);
      margin-right: 2rem;
      height: inherit;

      select {
         font-size: 1.2rem;
         margin-left: 1rem;
      }

      label {
         font-size: 1.2rem;
         font-weight: bold;
         color: white;
         margin: 0;
      }
   }
}
