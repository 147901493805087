.keno-odigrani-brojevi-table-container {
   @include flex(row, center, center);

   .odigrani-broj {
      @include flex(row, center, center);
      border: 0.1rem solid #ddd;
      font-weight: 900;
      height: 2rem;
      margin: 0 0.6rem;
      width: 2rem;

      &.dobitni {
         background-color: dodgerblue;
         color: #fff;
      }
   }
}
