.custom-toast-container {
   font-size: 1.1rem;

   .toast-loading-icon {
      font-size: 2.2rem;
   }

   .name {
      font-weight: 700;
      color: red;
   }
   .message {
      font-weight: 400;
   }

   .axios-url {
      font-weight: 100;
      font-size: 1rem;
      width: 20rem;
   }
}
